import React, { useState, useEffect, useRef } from 'react';
import { Star } from 'lucide-react';
import Slider from "react-slick";
import NextArrowSvg from '../../../public/svg/next-arrow-gallery.svg';
import PrevArrowSvg from '../../../public/svg/prev-arrow-gallery.svg';

const FeedbackCard = ({ name, rating, text, image_url, google_review_id, google_place_id }) => {
    const google_maps_url = google_review_id ? `https://search.google.com/local/reviews?placeid=${google_place_id}&rid=${google_review_id}` : null;

    const [isExpanded, setIsExpanded] = useState(false);
    const textRef = useRef(null);
    const [isTextClamped, setIsTextClamped] = useState(false);

    useEffect(() => {
        const checkTextClamped = () => {
            if (textRef.current) {
                const isClamped = textRef.current.scrollHeight > textRef.current.clientHeight;
                setIsTextClamped(isClamped);
            }
        };

        checkTextClamped();
        window.addEventListener('resize', checkTextClamped);

        return () => window.removeEventListener('resize', checkTextClamped);
    }, [text]);

    return (
        <div className="w-[85dvw] md:w-[42dvw]">
            <div className="flex flex-col bg-white rounded-lg shadow-md px-6 py-5 gap-4 relative">
                <div className="flex items-center content-between gap-4">
                    <h3 className="font-semibold text-lg lg:text-2xl">{name}</h3>
                    <div className="flex gap-1 lg:gap-4 ml-auto">
                        {[...Array(5)].map((_, i) => (
                        <Star
                            key={i}
                            size={16}
                            className={(i < rating ? "fill-primary text-primary" : "text-gray-300") + ' lg:w-6 lg:h-6'}
                        />
                        ))}
                    </div>
                </div>
                <div className="relative">
                    <p ref={textRef} 
                       className={`text-gray-900 ${isExpanded ? '' : 'line-clamp-8 sm:line-clamp-6'} text-lg lg:text-2xl mb-4`}>
                        {text}
                    </p>
                    {google_review_id && (
                        <a 
                            href={google_maps_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary hover:underline text-base"
                        >
                            from Google
                        </a>
                    )}
                </div>
                <div className="w-16 h-16 lg:w-28 lg:h-28 mb-[-3em] lg:mb-[-4.8em] rounded-full overflow-hidden">
                    <img 
                        src={image_url ? 
                            (image_url.startsWith('http') ? image_url : `${window.location.origin}${image_url}`)
                            : `https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                        alt={name} 
                        className="w-full h-full object-cover"
                        onError={(e) => {
                            e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`;
                        }}
                    />
                </div>
                {isTextClamped && (
                    <button
                        type='button'
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="absolute bottom-5 right-6 text-primary hover:underline text-base text-lg lg:text-2xl font-medium"
                    >
                        {isExpanded ? 'thanks!' : 'read more'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default function FeedbackSection ({ className = "", siteSetting }) {
    const [feedbacks, setFeedbacks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Create a ref for a slider
    const sliderRef = useRef(null);

    useEffect(() => {
        const fetchFeedbacks = async () => {
            try {
            const response = await fetch('/wt/api/feedbacks/');
            if (!response.ok) throw new Error('Failed to fetch feedbacks');
            const data = await response.json();
            setFeedbacks(data);
            } catch (err) {
            setError(err.message);
            } finally {
            setIsLoading(false);
            }
        };

        fetchFeedbacks();
    }, []);

    // Define custom arrow components
    function FeedbacksNextArrow(props) {
        const { className, style } = props;
        return (
            <button
            type='button'
            className={`${className} sm:w-16 sm:h-16 w-12 h-12 text-primary hover:text-black before:content-none z-10`}
            style={{ ...style, display: "block" }}
            onClick={() => sliderRef.current.slickNext()}>
                <NextArrowSvg className="h-full max-h-full w-full max-w-full"/>
            </button>
        );
      }
      
    function FeedbacksPrevArrow(props) {
        const { className, style } = props;
        return (
            <button
            type='button'
            className={`${className} sm:w-16 sm:h-16 w-12 h-12 text-primary hover:text-black before:content-none z-10`}
            style={{ ...style, display: "block" }}
            onClick={() => sliderRef.current.slickPrev()}>
                <PrevArrowSvg className="h-full max-h-full w-full max-w-full"/>
            </button>
        );
    }

    
    // Slick slider configuration
    const sliderSettings = {
        arrows: false,
        dots: false,
        infinite: feedbacks.length > 2,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1,
            }
          },
        ]
      };


    if (isLoading) {
        return (
        <section id="feedback" className={`container-main relative z-10 sm:scroll-m-40 scroll-m-24 ${className}`}>
            <h2 className="text-vw-8xl font-bold leading-min relative z-10">
            Feedback
            </h2>
            <div className='sm:centered-y w-full sm:h-vw-72 sm:mt-0 mt-vw-24'>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {[...Array(3)].map((_, i) => (
                    <div key={i} className="animate-pulse">
                    <div className="bg-gray-200 rounded-lg h-48"></div>
                    </div>
                ))}
                </div>
            </div>
            
        </section>
        );
    }

    if (feedbacks.length === 0) {
      return null;
    }

    if (error) {
        return (
        <section id="feedback" className={`container-main relative z-10 sm:scroll-m-40 scroll-m-24 ${className}`}>
            <h2 className="text-vw-8xl font-bold leading-min relative z-10">
                Feedback
            </h2>
            <div className='sm:centered-y w-full sm:h-vw-72 sm:mt-0 mt-vw-24'>
                <p className="text-center text-gray-600">
                    Unable to load feedbacks at this time. Please try again later.
                </p>
            </div>
        </section>
        );
    }

    return (
        <section id="feedback" className={`relative z-10 sm:scroll-m-40 scroll-m-24 ${className}`}>
            <div className="container-main sm:flex justify-between mb-10 sm:mb-14 sm:pb-8">
                <h2 className="text-vw-8xl font-bold leading-min relative z-10">
                    Feedback
                </h2>
                <div className="slider-controls hidden sm:flex items-center gap-12">
                    <FeedbacksPrevArrow />
                    <FeedbacksNextArrow />
                </div>
            </div>
            <div className="feedbacks-slider relative">
                <style>{`
                    .feedbacks-slider .slick-slide {
                        padding-left:clamp(20px, 3.125vw, 60px);
                        padding-bottom:clamp(3em, 12vw, 6em);
                    }
                `}</style>
                <Slider 
                    ref={sliderRef} 
                    {...sliderSettings}>
                    {feedbacks.map((feedback) => (
                        <FeedbackCard key={feedback.id} {...feedback} google_place_id={siteSetting.googlePlaceId} />
                    ))}
                </Slider>
            </div>
                
            <div className="slider-controls sm:hidden flex items-center content-around gap-12 mt-8">
                <FeedbacksPrevArrow className="ml-auto"/>
                <FeedbacksNextArrow  className="mr-auto"/>
            </div>
        </section>
    );
}